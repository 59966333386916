<template>
    <div class="container login">

        <!-- Logo and title -->
        <img src="https://res.cloudinary.com/wefix/image/upload/v1575386499/logos/wefix-logo-white.svg" alt="WeFix" class="logo img-4x" />
        <h1>Login</h1>

        <!-- Email input -->
        <div class="form-group">
            <input type="email" class="form-control form-control-lg" v-model="email" placeholder="Email">
        </div>

        <!-- Password input -->
        <div class="form-group">
            <input type="password" class="form-control form-control-lg" v-model="password" v-on:keyup.enter="login()" placeholder="Password">
        </div>

        <!-- Login button -->
        <div class="row">
            <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                <button type="submit" class="btn btn-success btn-block btn-lg btn-rounded" @click="login()">Log in</button>
            </div>
        </div>

        <div class="disclaimer-text">
            <p>
                *Only authorised users can access this system and legal action may be taken against unauthorised users, under the computer misuse act of 1990.
            </p>
        </div>

    </div>
</template>

<script>
    import {mapActions} from 'vuex';

    export default {
        name: "LoginIndex",

        data: function() {
            return {
                email: null,
                password: null
            }
        },

        methods: {
            ...mapActions(['fetchUser', 'displayToast']),

            login() {
                if (!this.email || !this.password) {
                    this.displayToast({text: 'Enter your login credentials.', type: 'error'});
                    return;
                }

                this.fetchUser({email: this.email, password: this.password})
                    .then(() => {
                        window.location.href = '/';
                    })
                    .catch(errors => {
                        this.displayToast({text: errors, type: 'error'});
                    })
            }
        }
    }
</script>